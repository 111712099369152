var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"p-6",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('DialogTitle',{staticStyle:{"margin-left":"-10px","margin-right":"-10px"},attrs:{"label":"Edit Prompt","align":"center"}}),_c('div',{staticClass:"my-3"},[_c('label',{staticClass:"text-uppercase font-bold"},[_vm._v("Choose Module")]),_c('RadioButtonComponent',{attrs:{"active-class":"font-semibold","label-class":"text-capitalize","items":[
          { id: 1, label: 'PTE Practice' },
          { id: 2, label: 'Practice Test' },
          { id: 3, label: 'Mock Test' },
          { id: 4, label: 'Quiz Test' },
        ],"message":!_vm.$v.form.module.required && _vm.$v.form.module.$dirty
            ? 'Field is required'
            : null},model:{value:(_vm.$v.form.module.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.module, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.module.$model"}})],1),_c('div',{staticClass:"my-3"},[_c('label',{staticClass:"text-uppercase font-bold"},[_vm._v("Choose Section")]),_c('RadioButtonComponent',{attrs:{"active-class":"font-semibold","label-class":"text-capitalize","items":[
          { id: 1, label: 'Reading' },
          { id: 2, label: 'Writing' },
          { id: 3, label: 'Speaking' },
          { id: 4, label: 'Listening' },
        ],"message":!_vm.$v.form.section.required && _vm.$v.form.section.$dirty
            ? 'Field is required'
            : null},model:{value:(_vm.$v.form.section.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.section, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.section.$model"}})],1),_c('div',{staticClass:"my-3"},[_c('SelectComponent',{staticClass:"w-74",attrs:{"label":"Choose Item","message":!_vm.$v.form.item.required && _vm.$v.form.item.$dirty
            ? 'Field is required'
            : null},model:{value:(_vm.$v.form.item.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.item, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.item.$model"}})],1),_c('div',{staticClass:"my-3"},[_c('label',{staticClass:"text-uppercase inline-block font-bold mb-3"},[_vm._v("Prompt")]),_c('TextEditor',{attrs:{"label":false,"message":!_vm.$v.form.prompt.required && _vm.$v.form.prompt.$dirty
            ? 'Field is required'
            : null},model:{value:(_vm.$v.form.prompt.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.prompt, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.prompt.$model"}})],1),_c('div',{staticClass:"my-3 flex justify-between"},[_c('RadioButtonComponent',{attrs:{"active-class":"font-semibold","label-class":"text-capitalize","items":[{ id: 1, label: 'Mark as active' }]}}),_c('div',[_c('md-button',{staticClass:"bg-default text-uppercase rounded",on:{"click":function($event){return _vm.dialog(false)}}},[_vm._v("Cancel")]),_c('md-button',{staticClass:"bg-victoria text-white text-uppercase rounded",attrs:{"type":"submit"}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }