<template>
        <div class="p-6">
            <div class="md-layout md-gutter">
                <div class="md-layout-item md-xsmall-size-100 md-large-size-100">
                   <h2 class="my-3 ml-6 text-center">  We are looking for Briliant Trainer for APICAL </h2>
                   <h3 class="my-3 ml-6 text-center text-uppercase text-victoria">Name of the Candidate : {{content.name}}</h3>

                </div>
            </div>
            <div class="md-layout md-gutter">
                <div class="md-layout-item md-xsmall-size-100 md-large-size-100">
                <Tab :items="tabs" class="my-10" >
                <template v-slot:icon="{ item }">
                    <span v-html="item.icon"></span>
                </template>
                </Tab>
                <router-view />
                </div>
            </div>

            <div class="md-layout md-gutter">
                <div class="md-layout-item md-xsmall-size-100 md-large-size-100">
                    <h2>PTE SCORE</h2>
                    <Content labelClass="font-bold text-victoria" textColor="black" class="my-3" label="Email Address" :text="content.email" />
                    <Content labelClass="font-bold text-victoria" textColor="black" class="my-3" label="Contact Number" :text="content.contact_number" />
                    <Content labelClass="font-bold text-victoria" textColor="black" class="my-3" label="Address"  text="Dhaka Bangladesh" />
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-xsmall-size-25 md-large-size-25">
                            <Content labelClass="font-bold text-victoria" textColor="black" class="my-3" label="Post Code" text="1216" />
                        </div>
                        <div class="md-layout-item md-xsmall-size-25 md-large-size-25">
                            <Content labelClass="font-bold text-victoria" textColor="black" class="my-3" label="City" text="Dhaka" />
                        </div>
                        <div class="md-layout-item md-xsmall-size-25 md-large-size-25">
                            <Content labelClass="font-bold text-victoria" textColor="black" class="my-3" label="State" text="Dhaka" />
                        </div>
                        <div class="md-layout-item md-xsmall-size-25 md-large-size-25">
                            <Content labelClass="font-bold text-victoria" textColor="black" class="my-3" label="Country" text="Bangladesh" />
                        </div>
                    </div>
                    <Content labelClass="font-bold text-victoria" textColor="black" class="my-3" label="Expected Salary"  :text="'$'+content.expectedSalary" />
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-xsmall-size-25 md-large-size-25">
                            <Content labelClass="font-bold text-victoria" textColor="black" class="my-3" label="CV"  :text="content.cv" />
                        </div>
                        <div class="md-layout-item md-xsmall-size-25 md-large-size-25">
                            <Content labelClass="font-bold text-victoria" textColor="black" class="my-3" label="PTE Score-Card"  :text="content.pte_score_card" />
                        </div>
                    </div>
                    <Content labelClass="font-bold text-victoria" class="my-3" label="Educational Qualification" />
                    <div v-for="(key, index) in JSON.parse(content.educational_details)" :key="index"  >
                        <Content labelClass="font-bold text-black" contenClass="text-black" textColor="black" class="my-3" :label="key.institue" :text="key.course" />
                    </div>
                    <Content labelClass="font-bold text-victoria" class="my-3" label="Employeement History" />
                    <div v-for="(key, index) in JSON.parse(content.experience_details)" :key="'EH_'+index"  >
                        <Content labelClass="font-bold text-black" contenClass="text-black" textColor="black" class="my-3" label="Company Name" :text="key.company" />
                        <div> <span class="mr-10">{{key.designation}}</span>  {{key.from_date?customFormatter(key.from_date):''}} To {{key.to_date?customFormatter(key.to_date):'Continue'}}</div> 
                    </div>
                    <Content labelClass="font-bold text-victoria" textColor="black"  class="my-3" label="Why you have Chosen Us"  :text="content.choosing_reason.replace(/<\/?[^>]+>/ig, '')"/>
                    <Content labelClass="font-bold text-victoria" class="my-3 questions" label="Questions" />  
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item md-size-100 mb-1" v-for="(input,k) in questions" :key="'Q_'+k">
                            <label  class="font-bold text-victoria text-uppercase">Question {{k+1}}#</label>
                            <CheckboxComponent v-if="input.question_type=='CHECK_LIST'"
                                :label="input.title"
                                label-class="font-bold"
                                titleClass="text-victoria"
                                :label-outline="false"
                                :block="true"
                                :items="getItems(input.job_question_details)"

                                />
                            <TextAreaComponent v-if="input.question_type=='SHORT_ANSWER'"
                                    placeholder="Write Question"
                                    :label="input.title"
                                    :rows = 5
                                    :value = "input.job_question_details[0].answer"
                                />
                                <TextAreaComponent v-if="input.question_type=='LONG_ANSWER'"
                                    placeholder="Write Question"
                                    :label="input.title"
                                    :rows = 10
                                    :value = "input.job_question_details[0].answer"
                                />
                                <label v-if="input.question_type=='RADIO_BUTTON'" class="text-uppercase font-bold text-xl mb-2 block mt-6"> {{input.title}} </label>
                                <RadioButtonComponent v-if="input.question_type=='RADIO_BUTTON'"
                                label-class="font-bold"
                                align="align-left"
                                :label-outline="false"
                                :block="true"
                                :items="getItems(input.job_question_details)"
                                />
                        </div>
                    </div>
                </div>
            </div>

                       
            <div class="flex absolute ">
                <md-button class="my-3 ml-6 bg-danger text-white text-uppercase rounded absolate left-0" @click.native="deleteJob(content.jobId)">X Delete</md-button>
            </div>
            <div class="flex justify-center">
                <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
                <md-button class="bg-victoria text-white text-uppercase rounded" >Go To Edit Page</md-button>
            </div>

            <ConfirmDialog 
            @confirm="actDeleteJob(jobId)"
            :active.sync="active"
            />
        </div>
        
</template>

<script>

import { 
  RadioButtonComponent,
  TextAreaComponent,
  CheckboxComponent,
  ConfirmDialog
  } from "@/components";
import moment from 'moment';
import Tab from "@/components/atom/Tab";
import Content from "@/components/atom/Content";
import { mapMutations, mapActions } from "vuex";
import VueClipboard from 'vue-clipboard2';
import Vue from 'vue';
import data from "@/data/master/qatm/data";

Vue.use(VueClipboard)

export default {
  components: {
    Content,
    RadioButtonComponent,
    TextAreaComponent,
    CheckboxComponent,
    ConfirmDialog,
    Tab,
  },
   props: {
      content: {
          type: Object
      }
  },
  data: function () {
    return {
      active: false,
      jobId : '',
      jobUrl: window.location.origin+'/job-circular/'+this.content.jobId,
      range: 0,
      copyMessage:'',
      tabs: data.applicantDetailsView,
      questions: [
        {
            "id": "85f104ba-8fbc-41fe-8757-23ba6e3d66de",
            "job_circular_id": "ed3c9682-37cd-4d2f-b66e-c6beda2d994f",
            "title": "Which of the following tests is used for English language testing",
            "question_type": "CHECK_LIST",
            "is_mandatory": 1,
            "created_by": "82db2f4a-95c2-4600-afca-45f4050073de",
            "job_question_details": [
                {
                    "id": "65438bdd-d041-46aa-bd23-83bc62003a8e",
                    "job_question_id": "85f104ba-8fbc-41fe-8757-23ba6e3d66de",
                    "sample_answer": null,
                    "key": "TEST1",
                    "value": "IELTS",
                    "is_correct_answer": 1,
                    "deleted_at": null,
                    "created_at": "2021-03-18T12:13:38.000000Z",
                    "updated_at": "2021-03-18T12:13:38.000000Z",
                    "answer": 'md-checked'
                },
                {
                    "id": "693aec04-57d6-4a6b-8325-e459ef07e5a1",
                    "job_question_id": "85f104ba-8fbc-41fe-8757-23ba6e3d66de",
                    "sample_answer": null,
                    "key": "TEST2",
                    "value": "PTE",
                    "is_correct_answer": 0,
                    "deleted_at": null,
                    "created_at": "2021-03-18T12:13:38.000000Z",
                    "updated_at": "2021-03-18T12:13:38.000000Z",
                    "answer": 0
                },
                {
                    "id": "b569aeb0-0c52-4a2e-9942-74a07f2f8431",
                    "job_question_id": "85f104ba-8fbc-41fe-8757-23ba6e3d66de",
                    "sample_answer": null,
                    "key": "TEST3",
                    "value": "EYE",
                    "is_correct_answer": 1,
                    "deleted_at": null,
                    "created_at": "2021-03-18T12:13:38.000000Z",
                    "updated_at": "2021-03-18T12:13:38.000000Z",
                    "answer": 'md-checked'
                }
            ]
        },
        {
            "id": "85f104ba-8fbc-41fe-8757-23ba6e3d66de1",
            "job_circular_id": "ed3c9682-37cd-4d2f-b66e-c6beda2d994f",
            "title": "Which of the following tests is used for English language testing",
            "question_type": "SHORT_ANSWER",
            "is_mandatory": 1,
            "created_by": "82db2f4a-95c2-4600-afca-45f4050073de",
            "job_question_details": [
                {
                    "id": "65438bdd-d041-46aa-bd23-83bc62003a8e",
                    "job_question_id": "85f104ba-8fbc-41fe-8757-23ba6e3d66de",
                    "sample_answer": null,
                    "key": "TEST1",
                    "value": "IELTS",
                    "is_correct_answer": 1,
                    "deleted_at": null,
                    "created_at": "2021-03-18T12:13:38.000000Z",
                    "updated_at": "2021-03-18T12:13:38.000000Z",
                    "answer": 'Testfasdfasd'
                }
            ]
        },
        {
            "id": "85f104ba-8fbc-41fe-8757-23ba6e3d66de2",
            "job_circular_id": "ed3c9682-37cd-4d2f-b66e-c6beda2d994f",
            "title": "Which of the following tests is used for English language testing",
            "question_type": "LONG_ANSWER",
            "is_mandatory": 1,
            "created_by": "82db2f4a-95c2-4600-afca-45f4050073de",
            "job_question_details": [
                {
                    "id": "65438bdd-d041-46aa-bd23-83bc62003a8e",
                    "job_question_id": "85f104ba-8fbc-41fe-8757-23ba6e3d66de",
                    "sample_answer": null,
                    "key": "TEST1",
                    "value": "IELTS",
                    "is_correct_answer": 1,
                    "deleted_at": null,
                    "created_at": "2021-03-18T12:13:38.000000Z",
                    "updated_at": "2021-03-18T12:13:38.000000Z",
                    "answer": 'Testfasdfasd1'
                }
                
            ]
        },
        {
            "id": "85f104ba-8fbc-41fe-8757-23ba6e3d66de3",
            "job_circular_id": "ed3c9682-37cd-4d2f-b66e-c6beda2d994f",
            "title": "Which of the following tests is used for English language testing",
            "question_type": "RADIO_BUTTON",
            "is_mandatory": 1,
            "created_by": "82db2f4a-95c2-4600-afca-45f4050073de",
            "job_question_details": [
                {
                    "id": "65438bdd-d041-46aa-bd23-83bc62003a8e",
                    "job_question_id": "85f104ba-8fbc-41fe-8757-23ba6e3d66de",
                    "sample_answer": null,
                    "key": "TEST1",
                    "value": "IELTS",
                    "is_correct_answer": 1,
                    "deleted_at": null,
                    "created_at": "2021-03-18T12:13:38.000000Z",
                    "updated_at": "2021-03-18T12:13:38.000000Z",
                    "answer": 'md-unchecked'
                    
                },
                {
                    "id": "693aec04-57d6-4a6b-8325-e459ef07e5a1",
                    "job_question_id": "85f104ba-8fbc-41fe-8757-23ba6e3d66de",
                    "sample_answer": null,
                    "key": "TEST2",
                    "value": "PTE",
                    "is_correct_answer": 1,
                    "deleted_at": null,
                    "created_at": "2021-03-18T12:13:38.000000Z",
                    "updated_at": "2021-03-18T12:13:38.000000Z",
                    "answer": 'md-checked'
                },
                {
                    "id": "b569aeb0-0c52-4a2e-9942-74a07f2f8431",
                    "job_question_id": "85f104ba-8fbc-41fe-8757-23ba6e3d66de",
                    "sample_answer": null,
                    "key": "TEST3",
                    "value": "EYE",
                    "is_correct_answer": 0,
                    "deleted_at": null,
                    "created_at": "2021-03-18T12:13:38.000000Z",
                    "updated_at": "2021-03-18T12:13:38.000000Z",
                    "answer": 'md-unchecked'
                }
            ]
        }
      ]
    }
  },
  computed: {
      total: function () {
      return this.range * 10
    }
  },
  methods: {
      ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actGetJobList: 'job/actGetJobList',
      actDeleteJob: "job/actDeleteJob",
    }),
    customFormatter(date) {
      return moment(date).format('D MMM, YYYY');
    },
    onApplicantInformation(item) {
      this.content = item;
      this.component = 'ApplicantInformation';
      this.dialog(true);
    },
    getItems(data){

        return data.map((item) =>{

          return { id: item.key, label: item.value, active:item.answer }
        });
    },
    deleteJob(jobId) {
       
      this.active = true;
      this.jobId = jobId;
    //   this.setShowSnackbar('Job deleted successfully !');
    //   setTimeout(() => {
    //    this.dialog(false);
    //   }, 1000);
    },
     onCopy: function (e) {
         if(!this.copyMessage)
         this.copyMessage='You just copied: ' + e.text;
         else
         this.copyMessage = '';
    },
    onError: function (e) {
      
      if(!this.copyMessage)
         this.copyMessage='Failed to copy texts';
     else
        this.copyMessage = '';
        console.log(e);
    }
  }
}
</script>
<style scoped>
.questions{
  font-size: 20px;
}
    
</style>