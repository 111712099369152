var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"p-6",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('DialogTitle',{staticStyle:{"margin-left":"-10px","margin-right":"-10px"},attrs:{"label":"Create End Communication","align":"center"}}),_c('div',{staticClass:"my-3"},[_c('label',{staticClass:"text-uppercase font-bold"},[_vm._v("Choose Type")]),_c('RadioButtonComponent',{attrs:{"active-class":"font-semibold","label-class":"text-capitalize","items":[
          { id: 1, label: 'Practice Test' },
          { id: 2, label: 'Mock Test' },
          { id: 3, label: 'Quiz Test' },
        ],"message":!_vm.$v.form.module.required && _vm.$v.form.module.$dirty
            ? 'Field is required'
            : null},model:{value:(_vm.$v.form.module.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.module, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.module.$model"}})],1),_c('div',{staticClass:"md-layout my-3"},[_c('div',{staticClass:"md-layout-item md-size-40"},[_c('SelectComponent',{staticClass:"w-full",attrs:{"label":"Choose Practice Test","items":[
            { id: 1, name: 'Reading' },
            { id: 2, name: 'Writing' },
            { id: 3, name: 'Speaking' },
            { id: 4, name: 'Listening' },
          ],"message":!_vm.$v.form.item.required && _vm.$v.form.item.$dirty
              ? 'Field is required'
              : null},model:{value:(_vm.$v.form.item.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.item, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.item.$model"}})],1),_c('div',{staticClass:"md-layout-item md-size-50"},[_c('SearchComponent',{staticClass:"p-2 mt-6",attrs:{"placeholder":"Search"}})],1)]),_c('div',{staticClass:"md-layout my-3"},[_c('div',{staticClass:"md-layout-item md-size-40"},[_c('SelectComponent',{staticClass:"w-full",attrs:{"label":"Choose Group","items":[
            { id: 1, name: 'Reading' },
            { id: 2, name: 'Writing' },
            { id: 3, name: 'Speaking' },
            { id: 4, name: 'Listening' },
          ],"message":!_vm.$v.form.item.required && _vm.$v.form.item.$dirty
              ? 'Field is required'
              : null},model:{value:(_vm.$v.form.item.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.item, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.item.$model"}})],1)]),_c('div',{staticClass:"my-3"},[_c('label',{staticClass:"text-uppercase font-bold"},[_vm._v("Number of Slide")]),_c('RadioButtonComponent',{attrs:{"active-class":"font-semibold","label-class":"text-capitalize","items":[
          { id: 1, label: '1' },
          { id: 2, label: '2' },
          { id: 3, label: '3' },
        ],"message":!_vm.$v.form.module.required && _vm.$v.form.module.$dirty
            ? 'Field is required'
            : null},model:{value:(_vm.$v.form.module.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.module, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.module.$model"}})],1),_c('div',{staticClass:"md-layout md-gutter my-3"},[_c('div',{staticClass:"md-layout-item md-size-100"},[_c('label',{staticClass:"text-uppercase inline-block font-bold mb-3"},[_vm._v("End Communication")]),_c('TextEditor',{attrs:{"label":false,"message":!_vm.$v.form.prompt.required && _vm.$v.form.prompt.$dirty
              ? 'Field is required'
              : null},model:{value:(_vm.$v.form.prompt.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.prompt, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.prompt.$model"}})],1),_c('div',{staticClass:"md-layout-item md-size-100 my-6"},[_c('InputFieldComponent',{attrs:{"italic":"","label":"Test Link You Want To Add On Last","placeholder":"Enter test link","message":!_vm.$v.form.duration.required && _vm.$v.form.duration.$dirty ? 'Field is required' : null},model:{value:(_vm.$v.form.duration.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.duration, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.duration.$model"}})],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('label',{staticClass:"text-uppercase inline-block font-bold mb-3"},[_vm._v("Message")]),_c('TextEditor',{attrs:{"label":false,"message":!_vm.$v.form.prompt.required && _vm.$v.form.prompt.$dirty
              ? 'Field is required'
              : null},model:{value:(_vm.$v.form.prompt.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.prompt, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.prompt.$model"}})],1)]),_c('div',{staticClass:"my-3 flex justify-between"},[_c('RadioButtonComponent',{attrs:{"active-class":"font-semibold","label-class":"text-capitalize","items":[{ id: 1, label: 'Mark as active' }]}}),_c('div',[_c('md-button',{staticClass:"bg-default text-uppercase rounded",on:{"click":function($event){return _vm.dialog(false)}}},[_vm._v("Cancel")]),_c('md-button',{staticClass:"bg-victoria text-white text-uppercase rounded",attrs:{"type":"submit"}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }