var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-6"},[_c('DialogTitle',{attrs:{"label":"Bulk Question Upload","align":"center"}}),_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item my-3"},[_c('SelectComponent',{staticClass:"w-full",attrs:{"label":"Question Section","items":_vm.sections,"return-type":"object"},on:{"getObject":_vm.onChangeSection}})],1),_c('div',{staticClass:"md-layout-item my-3"},[_c('SelectComponent',{staticClass:"w-full",attrs:{"items":_vm.questionTypes,"label":"Question Item","message":!_vm.$v.form.questionType.required && _vm.$v.form.questionType.$dirty
                ? 'Field is required'
                : null},model:{value:(_vm.$v.form.questionType.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.questionType, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.questionType.$model"}})],1),_c('div',{staticClass:"md-layout-item md-size-100 mt-3"},[_c('FileInputCompoment',{attrs:{"label":"Import file","message":!_vm.$v.form.file.required && _vm.$v.form.file.$dirty
                ? 'Field is required'
                : null},model:{value:(_vm.$v.form.file.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.file, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.file.$model"}})],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('TextAreaComponent',{attrs:{"label":"Notes","label-class":"text-italic","message":!_vm.$v.form.notes.required && _vm.$v.form.notes.$dirty
                ? 'Field is required'
                : null},model:{value:(_vm.$v.form.notes.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.notes, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.notes.$model"}})],1)]),_c('div',{staticClass:"md-layout-item my-3"},[_c('label',{staticClass:"pr-4 text-center block mb-1 text-uppercase font-bold"},[_vm._v(" Preview ")]),_c('div',{staticClass:"rounded outline-gray-400 p-2",staticStyle:{"min-height":"250px"}}),_c('div',[_c('div',{staticClass:"flex justify-between p-1 mt-4"},[_c('md-icon',{staticClass:"bg-victoria p-3 text-white m-0 rounded text-2xl pointer",nativeOn:{"click":function($event){_vm.currentIndex--}}},[_vm._v("keyboard_arrow_left")]),_c('span',{staticClass:"mx-6 text-lg font-semibold"},[_vm._v("Question "+_vm._s(_vm.currentIndex + 1))]),_c('md-icon',{staticClass:"bg-victoria p-3 text-white m-0 rounded text-2xl pointer",nativeOn:{"click":function($event){_vm.currentIndex++}}},[_vm._v("keyboard_arrow_right")])],1)])])]),_c('div',{staticClass:"flex justify-end"},[_c('md-button',[_vm._v("Save as draft")]),_c('md-button',{staticClass:"outline-gray-400 rounded"},[_vm._v("Schedule")]),_c('md-button',{staticClass:"bg-victoria text-white rounded",on:{"click":_vm.onSubmit}},[_vm._v("Create")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }